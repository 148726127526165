import React, { createContext, useEffect, useState, useRef } from "react";
import SummaryApi from "../common/Apis";
import { toast } from "react-toastify";
import { redirect } from "react-router-dom";

export const Usercontext = createContext(null);

const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const tokenRef = useRef(localStorage.getItem("token")); // Track initial token

  // Function to fetch user data
  const fetchUserData = async () => {
    const token = localStorage.getItem("token"); // Always get the latest token from localStorage
    if (!token) {
      setUser(null); // Clear user if no token
      setLoading(false);
      return;
    }

    try {
      setLoading(true); // Start loading when fetching data
      const response = await fetch(SummaryApi.profile.url, {
        method: SummaryApi.profile.method,
        headers: {
          Authorization: token,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error ${response.status}: ${errorText}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Expected JSON, but got something else.");
      }

      const userData = await response.json();
      setUser(userData);
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error("Error fetching user profile:", error.message);
      setError(error.message);
      toast.error(error.message, { position: "top-right" });
    } finally {
      setLoading(false); // Ensure loading stops
    }
  };

  // Check token expiration and refresh if necessary
  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      console.log("No refresh token available.");
      redirect('/login');
    }

    try {
      const response = await fetch(SummaryApi.refreshToken.url, {
        method: SummaryApi.refreshToken.method,
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token); // Store the new token
        tokenRef.current = data.token; // Update the token reference
        fetchUserData(); // Fetch user data with the new token
      } else {
        console.log("Refresh token expired or invalid.");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        setUser(null);
        setLoading(false);
        toast.error("Session expired. Please log in again.");
      }
    } catch (error) {
      console.error("Error refreshing token:", error.message);
      toast.error("Time Out , Please login again.");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      setUser(null);
      setLoading(false);
    }
  };

  // Function to check if the token is expired
  // const isTokenExpired = (token) => {
  //   if (!token) return true;

  //   try {
  //     const payload = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
  //     const isExpired = Date.now() >= payload.exp * 1000; // Check if current time is greater than expiry

  //     if (isExpired) {
  //       console.log("Token expired.");
  //       return true;
  //     }
  //     return false;
  //   } catch (error) {
  //     console.error("Error checking token expiration:", error);
  //     return true; // Treat as expired
  //   }
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token ) {
      refreshToken(); // Refresh token if expired
    } else {
      fetchUserData(); // Fetch user data if token is valid
    }

    // Listen for token changes in localStorage
    const handleStorageChange = () => {
      if (tokenRef.current !== localStorage.getItem("token")) {
        tokenRef.current = localStorage.getItem("token");
        fetchUserData();
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    return () => {
      // Cleanup event listener on unmount
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <Usercontext.Provider value={{ user, loading, error, setUser, fetchUserData }}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        props.children
      )}
      {error && <div>Error: {error}</div>}
    </Usercontext.Provider>
  );
};

export default UserContextProvider;
