import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Fix import for jwt-decode

import { useLocation, useNavigate } from "react-router-dom";
import { FaCreditCard } from "react-icons/fa"; // Icon for payment card
import { AiOutlineLoading } from "react-icons/ai"; // Icon for loading spinner
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify styles
import SummaryApi from "../common/Apis"; // Assuming you have this in place
import { Usercontext } from "../Store/UserContext";
import UserNavbar from "../Component/Navbar/UserNavbar";

const UploadServices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(Usercontext);

  const { certificatename } = location.state || {}; // Fallback in case state is undefined
  const [certificateName, setCertificateName] = useState(certificatename || ""); // Fallback for certificate name
  const [proofOfIdentity, setProofOfIdentity] = useState([""]);
  const [proofOfAddress, setProofOfAddress] = useState([""]);
  const [availableIdentityDocs, setAvailableIdentityDocs] = useState([]);
  const [availableAddressDocs, setAvailableAddressDocs] = useState([]);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [payAmount, setAmount] = useState(0);

  // New States for form
  const [formFor, setFormFor] = useState("self"); // Dropdown state
  const [personName, setPersonName] = useState(user?.name || "");
  const [mobileNumber, setMobileNumber] = useState(user?.phoneNo || "");
  const [Address, setAddress] = useState(user?.address || "");
  // Extract user ID from JWT token
  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken._id); // Set the user ID from the decoded token
      // console.log(decodedToken._id);
    }
  }, []);

  useEffect(() => {
    if (formFor === "self") {
      setPersonName(user?.name || "");
      setMobileNumber(user?.phoneNo || "");
      setAddress(user?.address || "");
    } else {
      setPersonName("");
      setMobileNumber("");
      setAddress("");
    }
  }, [formFor, user]);
  // Fetch proof of identity and address documents from backend on component load
  useEffect(() => {
    const url = `${SummaryApi.documents.url}/${encodeURIComponent(
      certificateName
    )}`;
    console.log(url);
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(url);
        const { proofOfIdentity, proofOfAddress, amount } = response.data; // Adjust based on response structure
        setAmount(calculateGST(amount));
        setAvailableIdentityDocs(proofOfIdentity);
        setAvailableAddressDocs(proofOfAddress);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    if (certificateName) {
      fetchDocuments();
    }
  }, [certificateName]);

  // Handle adding more inputs for identity/address proof
  const handleAddField = (fieldType) => {
    if (fieldType === "identity") {
      setProofOfIdentity([...proofOfIdentity, ""]);
    } else if (fieldType === "address") {
      setProofOfAddress([...proofOfAddress, ""]);
    }
  };

  // Handle file upload
  const handleFileUpload = (e, fieldType, index) => {
    const file = e.target.files[0];
    if (fieldType === "identity") {
      const updatedFiles = [...proofOfIdentity];
      updatedFiles[index] = file;
      setProofOfIdentity(updatedFiles);
    } else if (fieldType === "address") {
      const updatedFiles = [...proofOfAddress];
      updatedFiles[index] = file;
      setProofOfAddress(updatedFiles);
    }
  };

  function calculateGST(amount) {
  const gstRate = 0.18;
  const gstAmount = amount * gstRate;
  const totalAmount = amount + gstAmount;
  return  totalAmount ;
}

  // Handle Payment
  const handlePayment = async () => {
    try {
      const url = SummaryApi.payment.url;
      const paymentResponse = await axios.post(url, {
        amount: payAmount,
      });

      const { amount, id: order_id, currency } = paymentResponse.data;

      if (typeof window.Razorpay === "undefined") {
        console.error("Razorpay SDK not loaded");
        toast.error("Payment gateway not available");
        return;
      }

      const options = {
        key: "rzp_live_j5FzhaF8uVrDAU",
        amount: amount,
        currency: currency,
        name: "Certificate Service",
        description: "Payment for certificate",
        order_id: order_id,
        handler: async (response) => {
          try {
            const paymentId = response.razorpay_payment_id;
            console.log("Payment successful:", paymentId);
            await handleSubmit();
          } catch (error) {
            console.error("Error during payment handling:", error);
          }
        },
        theme: { color: "#3399cc" },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      toast.error("Error initiating payment");
    }
  };

  // Handle form submission (called after successful payment)
  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("certificateName", certificateName);
    formData.append("formFor", formFor);
    formData.append("name", personName);
    formData.append("phoneNo", mobileNumber);
    formData.append("address", Address);

    // Append proof of identity files
    proofOfIdentity.forEach((file) => {
      if (file) {
        formData.append("proofOfIdentity", file);
      }
    });

    // Append proof of address files
    proofOfAddress.forEach((file) => {
      if (file) {
        formData.append("proofOfAddress", file);
      }
    });

    try {
      const token = localStorage.getItem("token");
      const url = `${SummaryApi.users.url}/${userId}/certificates`;
      await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Certificate details uploaded successfully");

      setTimeout(() => {
        navigate("/customer");
      }, 2000);
    } catch (error) {
      toast.error("Error uploading certificate details");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    navigate("/customer/serviceslist");
  };

  return (
    <>
      <UserNavbar />
      <div className="flex flex-col mt-4 px-4 sm:px-6 lg:flex-row lg:px-8">
        <div className="lg:ml-14 mt-5 lg:mt-0">
          {user.role === 'customer' &&
          <button
            className="bg-blue-700 text-white rounded-lg p-2 m-1 border"
            onClick={handleClick}
          >
            Go to Services List
          </button>
          }
        </div>
        <div className="w-full lg:w-[60%] mx-auto p-8 bg-white shadow-xl shadow-gray-400 mt-6 lg:mt-0">
          <h2 className="text-2xl font-bold mb-6">Add Certificate Details</h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Certificate Name <span className="text-red-500">*</span>
              </label>
              <input
                // type="text"
                value={certificateName}
                className="mt-1 p-2 block w-full border-2 border-gray-400 rounded-md"
                placeholder="Enter certificate name"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Filling Form For <span className="text-red-500">*</span>
              </label>
              <select
                value={formFor}
                onChange={(e) => setFormFor(e.target.value)}
                className="mt-1 p-2 block w-full border-2 border-gray-400 rounded-md"
              >
                <option value="self">Self</option>
                <option value="son">Son</option>
                <option value="daughter">Daughter</option>
                <option value="wife">Wife</option>
              </select>
            </div>
            <div className="flex flex-col gap-2 sm:flex-row sm:gap-4">
              {formFor && (
                <>
                  <div className="w-1/2">
                    <label className="block text-sm font-medium text-gray-700">
                      Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      value={personName}
                      onChange={(e) => setPersonName(e.target.value)}
                      className="mt-1 p-2 block w-full border-2 border-gray-400 rounded-md"
                      placeholder="Enter name"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Mobile Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      className="mt-1 p-2 block w-full border-2 border-gray-400 rounded-md"
                      placeholder="Enter mobile number"
                      required
                    />
                  </div>
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Address <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      rows="2"
                      type="text"
                      value={Address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="mt-1 p-2 block w-full  border-2 border-gray-400 rounded-md"
                      placeholder="Enter Address Here"
                      required
                    />
                  </div>
                </>
              )}
            </div>

            <div className="flex gap-2">
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Proof of Identity <span className="text-red-500">*</span>
                </label>
                {proofOfIdentity.map((_, index) => (
                  <div key={index} className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Proof of Identity
                    </label>
                    <select className="w-full mt-1 p-2 border rounded-md">
                      <option value="">Select Document</option>
                      {availableIdentityDocs.map((doc, idx) => (
                        <option key={idx} value={doc}>
                          {doc}
                        </option>
                      ))}
                    </select>
                    <input
                      type="file"
                      className="mt-1 block w-full border rounded-md"
                      onChange={(e) => handleFileUpload(e, "identity", index)}
                    />
                  </div>
                ))}
                <button
                  type="button"
                  className="bg-blue-700 text-white rounded-lg p-2"
                  onClick={() => handleAddField("identity")}
                >
                  Add More Identity Proof
                </button>
              </div>

              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Proof of Address <span className="text-red-500">*</span>
                </label>
                {proofOfAddress.map((_, index) => (
                  <div key={index} className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Proof of Address
                    </label>
                    <select className="w-full mt-1 p-2 border rounded-md">
                      <option value="">Select Document</option>
                      {availableAddressDocs.map((doc, idx) => (
                        <option key={idx} value={doc}>
                          {doc}
                        </option>
                      ))}
                    </select>
                    <input
                      type="file"
                      className="mt-1 block w-full border rounded-md"
                      onChange={(e) => handleFileUpload(e, "address", index)}
                    />
                  </div>
                ))}
                <button
                  type="button"
                  className="bg-blue-700 text-white rounded-lg p-2"
                  onClick={() => handleAddField("address")}
                >
                  Add More Address Proof
                </button>
              </div>
            </div>

            <div className="flex justify-center items-center ">
              <button
                type="button"
                onClick={handlePayment}
                className="bg-blue-700 w-full text-white rounded-lg p-2"
              >
                {loading ? (
                
                  <div className="flex justify-center gap-4">
                    {/* Spinning icon for loading */}
                    <AiOutlineLoading className="animate-spin text-white text-3xl" />
                    <span>Processing...</span>
                    </div>
                
                ) : (
                <div >
                    Submit Details and Pay
                    </div>
                
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UploadServices;
