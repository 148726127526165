import React from 'react'
import AppliedCertificates from './AppliedCertificates'

const UserDashbords = () => {
  return (
    <div><AppliedCertificates/>
    
    </div>
    

  )
}

export default UserDashbords