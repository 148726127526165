
// const backendDomain= 'http://localhost:8080/app'


const backendDomain=process.env.REACT_APP_API_URI
console.log(backendDomain)
const SummaryApi = {

    signUp: {
        url: `${backendDomain}/auth/signup`,
        method: "post"
    },
    signIn: {
        url: `${backendDomain}/auth/login`,
        method: "post"
    },
    profile: {
        url: `${backendDomain}/user/profile`,
        method: "Get"
    },
    addDepartment: {
        url: `${backendDomain}/dept/departments`,
        
    },
    addCertificates: {
        url: `${backendDomain}/dept/departments/add-certificates`,
        
    },
    updateCertificate:{
      url:`${backendDomain}/dept/departments/update-certificate`,
      method:"put"

    },
    users: {
        url: `${backendDomain}/user/users`,
        method: "Get"
    },
    documents: {
        url: `${backendDomain}/dept/documents`,
        method: "Get"
    },
    payment:{
        url:`${backendDomain}/payment/checkout`
    },
    sendOtp:{
        url:`${backendDomain}/send-otp`,
        method:"Post"
    },
    verifyOtp:{
        url:`${backendDomain}/verify-otp`,
        method:"Post"
    },
    adminUsers: {
        url: `${backendDomain}/super/adminusers`,
        method: "GET",
      },
      changeRole: {
        url: `${backendDomain}/super/changerole`,
        method: "POST",
      },
      deleteUser: {
        url: `${backendDomain}/user/users`,
        
      },
      assignedCertificates: {
        url: `${backendDomain}/assign/assigned-tasks`, // Adjust the URL as needed
        method: 'GET',
      },
      CertificatesStatusHandle:{
        url: `${backendDomain}/assign/certificates`,

      },
      CertificatesRevertHandle:{
        url:`${backendDomain}/revert/revert-certificate`
      },
      uplodeRevertDocuments:{
        url:`${backendDomain}/user/users`
      },
      UpdateUser:{
        url:`${backendDomain}/user/update-user`
      },
      getUserNameByCertificateId:{
        url: `${backendDomain}/user/username`
      
     },


}
export default SummaryApi;
