import { FaUser, FaTachometerAlt, FaBars, FaTimes, FaBriefcase, FaSearch } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { Usercontext } from '../../Store/UserContext';

const UserNavbar = () => {
  const { user, loading, error, setUser } = useContext(Usercontext);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("token");
    toast.success("Logged out successfully!", { position: "top-right" });
    navigate("/home");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleImageClick = () => {
    navigate("/dashbord");  // Navigate to the Services list page
  };

  useEffect(() => {
    console.log("Updated user state:", user);
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <nav className="bg-white p-3 flex justify-between items-center shadow-lg">
      <div className="flex items-center w-full">
        <img
          src="/images/YEL LOGO PNG.png"
          alt="YEL Seva Logo"
          className="w-30 h-16 object-contain ml-5 lg:ml-14 cursor-pointer"  // Add cursor-pointer class to indicate it's clickable
          onClick={handleImageClick}  // Navigate to the services list when the image is clicked
   
        />

        <button className="ml-auto text-2xl md:hidden" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>

        <ul
          className={`md:flex flex-col md:flex-row md:space-y-0 md:space-x-4 absolute md:static top-16 left-0 w-full md:w-auto bg-gray-200 md:bg-transparent p-4 md:p-0 transition-transform duration-300 ease-in-out ${menuOpen ? "block" : "hidden"} ml-auto`}
        >
          <li className="flex items-center p-4 rounded-md text-md">
            <IoMdHome className="mr-1" size={18} />
            <NavLink
              to="/"
              className={({ isActive }) => isActive ? 'text-blue-900   underline transition duration-200' : 'hover:underline  font-semibold transition duration-200'}
            >
              Home
            </NavLink>
          </li>
          <li className="flex items-center p-4 rounded-md text-md">
            <FaUser className="mr-1" />
            <NavLink
              to="/profile"
              className={({ isActive }) => isActive ? 'text-blue-900 underline transition duration-200' : 'hover:underline font-semibold transition duration-200'}
            >
               {loading ? 'Loading...' : (user?.name?.split(' ')[0]?.charAt(0).toUpperCase() + user?.name?.split(' ')[0]?.slice(1).toLowerCase() || 'User')}

            </NavLink>
          </li>

          {user?.role === "customer" && (
            <li className="flex items-center p-2 rounded-md text-md ml-2 ">
              <FaTachometerAlt className="mr-1 " />
              <NavLink
                to="/customer"
                className={({ isActive }) => isActive ? 'text-blue-900 underline transition duration-200' : 'hover:underline  font-semibold transition duration-200'}
              >
                Dashboard
              </NavLink>
            </li>
          )}
          {user?.role === "employee" && (
            <li className="flex items-center p-4 rounded-md text-md">
              <FaTachometerAlt className="mr-1" />
              <NavLink
                to="/employee"
                className={({ isActive }) => isActive ? 'text-blue-900 underline transition duration-200' : 'hover:underline  font-semibold transition duration-200'}
              >
                Employee
              </NavLink>
            </li>
          )}
          {user?.role === "admin" && (
            <li className="flex items-center p-4 rounded-md text-md">
              <FaTachometerAlt className="mr-1" />
              <NavLink
                to="/admin"
                className={({ isActive }) => isActive ? 'text-blue-900 underline transition duration-200' : 'hover:underline font-semibold transition duration-200'}
              >
                Admin
              </NavLink>
            </li>
          )}

          <li className="flex items-center p-4 rounded-md text-md">
            <FaSearch className="mr-1" />
            <NavLink
              to="/careers"
              className={({ isActive }) => isActive ? 'text-blue-900 underline transition duration-200' : 'hover:underline  font-semibold transition duration-200'}
            >
              Careers
            </NavLink>
          </li>
          {token &&
            <li className="md:hidden mt-4">
              <button
                onClick={handleLogout}
                className="bg-orange-600 h-10 w-full text-white py-2 px-4 rounded-full hover:bg-orange-700"
              >
                Logout
              </button>
            </li>
          }
        </ul>
      </div>

      <button
        onClick={handleLogout}
        className="hidden md:block bg-orange-600 h-10 text-white py-2 px-4 rounded-full hover:bg-orange-700"
      >
        Logout
      </button>
    </nav>
  );
};

export default UserNavbar;