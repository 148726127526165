const documentsList = [
    { value: 'Form D', label: 'Form D' },
    { value: 'E Aadhaar Card of Husband', label: 'E Aadhaar Card of Husband' },
    { value: 'E Aadhaar Card of Wife', label: 'E Aadhaar Card of Wife' },
    { value: 'Passport Size Photo of Husband & Wife', label: 'Passport Size Photo of Husband & Wife' },
    { value: 'Rs. 100 Court Fee Stamp Ticket', label: 'Rs. 100 Court Fee Stamp Ticket' },
    { value: 'Ration Card of Husband', label: 'Ration Card of Husband' },
    { value: 'Ration Card of Wife', label: 'Ration Card of Wife' },
    { value: 'Electricity Bill / Rent Agreement', label: 'Electricity Bill / Rent Agreement' },
    { value: 'PAN Card of Husband', label: 'PAN Card of Husband' },
    { value: 'PAN Card of Wife', label: 'PAN Card of Wife' },
    { value: 'Marriage Card of Husband & Wife', label: 'Marriage Card of Husband & Wife' },
    { value: 'School or College Leaving Certificate of Husband', label: 'School or College Leaving Certificate of Husband' },
    { value: 'School or College Leaving Certificate of Wife', label: 'School or College Leaving Certificate of Wife' },
    { value: 'Purohit Marriage Certificate', label: 'Purohit Marriage Certificate' },
    { value: 'Marriage Photo Big Size', label: 'Marriage Photo Big Size' },
    { value: '3 Witness People Aadhaar Card & 3 Passport Size Photo of Each', label: '3 Witness People Aadhaar Card & 3 Passport Size Photo of Each' },
    { value: 'Shapath Patra of Husband', label: 'Shapath Patra of Husband' },
    { value: 'Shapath Patra of Wife', label: 'Shapath Patra of Wife' },
    { value: 'True Copy of All Documents', label: 'True Copy of All Documents' },
    { value: 'Self Declaration of Husband', label: 'Self Declaration of Husband' },
    { value: 'Self Declaration of Wife', label: 'Self Declaration of Wife' },
    { value: 'Affidavit of Husband', label: 'Affidavit of Husband' },
    { value: 'Affidavit of Wife', label: 'Affidavit of Wife' },
    { value: 'All Family Members E Aadhaar Card (Biometrics Updated Year 2024)', label: 'All Family Members E Aadhaar Card (Biometrics Updated Year 2024)' },
    { value: 'All Family Members Passport Size Photo', label: 'All Family Members Passport Size Photo' },
    { value: 'All Family Members Birth Certificate / School or College Leaving Certificate', label: 'All Family Members Birth Certificate / School or College Leaving Certificate' },
    { value: 'All Family Members PAN Card of 18 Years & Above Only', label: 'All Family Members PAN Card of 18 Years & Above Only' },
    { value: 'All Family Members Bank Account Details of 18 Years & Above Only', label: 'All Family Members Bank Account Details of 18 Years & Above Only' },
    { value: 'Income Certificate Affidavit', label: 'Income Certificate Affidavit' },
    { value: 'New Ration Card Affidavit of Female HOF', label: 'New Ration Card Affidavit of Female HOF' },
    { value: 'Gas Booklet Details', label: 'Gas Booklet Details' },
    { value: 'Caste Certificate if in Category', label: 'Caste Certificate if in Category' },
    { value: 'Shop Act Licence', label: 'Shop Act Licence' },
    { value: 'Business Owner Sample Signature on White Paper', label: 'Business Owner Sample Signature on White Paper' },
    { value: 'Business Name', label: 'Business Name' },
    { value: 'Business Type', label: 'Business Type' },
    { value: 'Number of Employee on Job', label: 'Number of Employee on Job' },
    { value: 'Business Registration Date', label: 'Business Registration Date' },
    { value: 'Shop Photo', label: 'Shop Photo' },
    { value: 'Aadhaar Registered Mobile Number', label: 'Aadhaar Registered Mobile Number' },
    { value: 'Email ID', label: 'Email ID' },
    { value: 'One Family Member Name and Relation', label: 'One Family Member Name and Relation' },
    { value: 'Personal or Business Bank Account Details', label: 'Personal or Business Bank Account Details' },
    { value: 'UDYAM Licence', label: 'UDYAM Licence' },
    { value: 'Business Owner Photo', label: 'Business Owner Photo' },
    { value: 'Aadhaar Registered Mobile Number', label: 'Aadhaar Registered Mobile Number' },
    { value: 'Election Card', label: 'Election Card' },
    { value: 'Beneficiary Leaving Certificate', label: 'Beneficiary Leaving Certificate' },
    { value: 'Father Leaving Certificate', label: 'Father Leaving Certificate' },
    { value: '16 Years Old Connection Electricity Bill of MSEB', label: '16 Years Old Connection Electricity Bill of MSEB' },
    { value: '16 Years Old Land Record', label: '16 Years Old Land Record' },
    { value: 'Father Blood Relation Caste Certificate', label: 'Father Blood Relation Caste Certificate' },
    { value: 'Affidavit (Form 2 & Form 3)', label: 'Affidavit (Form 2 & Form 3)' },
    { value: '7/12, 8A', label: '7/12, 8A' },
    { value: 'Property Index 2', label: 'Property Index 2' },
    { value: 'Valuation Report', label: 'Valuation Report' },
    { value: 'Property Documents', label: 'Property Documents' },
    { value: 'Society Share Copy and NOC', label: 'Society Share Copy and NOC' },
    { value: 'Income Certificate 3 Year', label: 'Income Certificate 3 Year' },
    { value: 'Child Original Birth Certificate', label: 'Child Original Birth Certificate' },
    { value: 'Father Caste Certificate (For Category)', label: 'Father Caste Certificate (For Category)' },
    { value: 'Father or Mother Income Certificate (Below 1 Lakh - 1 Year)', label: 'Father or Mother Income Certificate (Below 1 Lakh - 1 Year)' },
    { value: 'Father Residence Proof (Electricity Bill / Registered Rent Agreement)', label: 'Father Residence Proof (Electricity Bill / Registered Rent Agreement)' },
    { value: 'E Aadhaar Card (Biometrics Updated Year 2024)', label: 'E Aadhaar Card (Biometrics Updated Year 2024)' },
    { value: 'Bank Passbook (Individual Saving Account)', label: 'Bank Passbook (Individual Saving Account)' },
    { value: 'Offline or Online Ration Card / Election Card / Birth Certificate / School or College Leaving Certificate', label: 'Offline or Online Ration Card / Election Card / Birth Certificate / School or College Leaving Certificate' },
    { value: 'Talathi Ahval', label: 'Talathi Ahval' }
  ];
  
  export default documentsList;